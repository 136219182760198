import httpService from '../../../helpers/http.service'

export default {

    save(params, id) {
        return httpService.put(`contracts/${id}`, params);
    },
    get(id, params = {}) {
        return httpService.get(`contracts/${id}`, {params});
    },
    changeStatus(id, params) {
        return httpService.patch(`contracts/${id}/status`, null,{params});

    },
    existsNBS(id) {
        return httpService.get(`contracts/${id}/exist-nbs`, null);

    }
}
