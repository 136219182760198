
export default {
    civilStatus: ['Soltero', 'Casado', 'Union Libre', 'Divorciado', 'Viudo'],
    typeHousing: ['Propia', 'Rentada', 'Prestada'],
    typeEmployee: ['Gerente', 'Secretaria', 'Abogado'],
    reEntry: 'Reingresada',
    ok: 'OK',
    nbs: 'NBS3',
    contractStatus: ['Anulada', 'Devuelta', 'OK', 'Pendiente', 'RB', 'Rechazada'],
    accountType: ['ahorros', 'corriente'],
    cards: [
        {
            name: 'DinersClub',
            value: 'dinners'
        },
        {
            name: 'Credencial',
            value: 'credencial'
        },
        {
            name: 'Red Multicolor',
            value: 'red_multicolor'
        },
        {
            name: 'America Express',
            value: 'american_express'
        },
        {
            name: 'Visa',
            value: 'visa'
        },
        {
            name: 'Mastercard',
            value: 'master_card'
        },
        {
            name: 'Discovery',
            value: 'discovery'
        },
    ]

}
